import type { SiteIdentifier } from "@xxl/common-utils";
import type {
  ContentModule,
  DigitalCampaignCategoryHeroBanner,
  DigitalCampaignContentData,
  DigitalCampaignHeroBanner,
  GridContainer,
  Level1Category,
} from "@xxl/content-api";
import { ContentApi } from "@xxl/content-api";
import { filterNull } from "@xxl/type-utils";
import { isDateWithinRange } from "../date-helper";
import { getContentApiConfiguration } from "../environment-variables";
import { getPreviewableContent } from "../preview-content";

const contentApi = new ContentApi(getContentApiConfiguration());

export type DigitalCampaignPageContent = {
  campaignId: string;
  campaignHeroBanner: DigitalCampaignHeroBanner;
  categoryBanners: DigitalCampaignCategoryHeroBanner[];
  gridSections: GridSection[];
  landingPageSelectorLabel?: string;
  productCodes: string[];
  productListingSmallBannersIds: string[];
  level1Categories: Level1Category[];
  ipaperLink?: string;
  additionalCampaignIds?: string[];
  abTestsPage?: DigitalCampaignContentData;
};

export type GridSection = {
  gridContainers?: GridContainer[];
  visibleContainersCount?: number | null;
  sectionName: string;
  buttonText?: string;
  buttonUrl?: string | null;
};

export const mapGridSectionFromContentModule = (
  contentModule: ContentModule
): GridSection => {
  return {
    gridContainers: contentModule.gridContainers,
    visibleContainersCount: contentModule.visibleContainersCount ?? null,
    sectionName: contentModule.sectionName as string,
    buttonText: contentModule.buttonText,
    buttonUrl: contentModule.categoryPath ?? contentModule.showMoreUrl ?? null,
  };
};

export const getDigitalCampaignPage = async (
  siteUid: SiteIdentifier,
  campaignId: string,
  documentId?: string,
  previewKey?: string
): Promise<DigitalCampaignPageContent | null> => {
  const response = await getPreviewableContent({
    getDefaultContentFn: () =>
      contentApi.getDigitalCampaignPage(siteUid, campaignId),
    getPreviewContentFn: () =>
      contentApi.getDigitalCampaignPagePreview(
        siteUid,
        String(documentId),
        String(previewKey)
      ),
    previewCondition: () =>
      previewKey !== undefined && documentId !== undefined,
  });

  if (response.data.result.length === 0) {
    throw new Error("Campaign doesn't exist");
  }

  const [digitalCampaignContent] = response.data.result;

  if (
    previewKey === undefined &&
    !isDateWithinRange({
      fromDate: digitalCampaignContent.schedule?.fromDate,
      toDate: digitalCampaignContent.schedule?.toDate,
    })
  ) {
    throw new Error("Campaign is inactive");
  }

  const gridSections =
    digitalCampaignContent.landingPage?.contentModules?.map((item) =>
      mapGridSectionFromContentModule(item)
    ) ?? [];
  const productCodes =
    digitalCampaignContent.landingPage?.productCodes !== undefined
      ? filterNull(digitalCampaignContent.landingPage.productCodes)
      : [];

  return {
    campaignId: digitalCampaignContent.campaignId,
    campaignHeroBanner: digitalCampaignContent.landingPageBanner,
    categoryBanners: digitalCampaignContent.categoryBanners ?? [],
    productListingSmallBannersIds:
      digitalCampaignContent.productListingSmallBannersIds ?? [],
    gridSections,
    productCodes,
    landingPageSelectorLabel:
      digitalCampaignContent.landingPage?.filterButtonLabel,
    level1Categories: digitalCampaignContent.level1Categories ?? [],
    ipaperLink: digitalCampaignContent.landingPage?.ipaperLink,
    additionalCampaignIds: digitalCampaignContent.additionalCampaignIds,
    abTestsPage: digitalCampaignContent.abTestsPage,
  };
};
